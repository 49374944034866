@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.button {
    width: 200px;
    height: 42px;
    // color: #D58C51;
    // background-color: #161516;
    border: 1px;
    border-color: #D58C51;
    border-style: solid;
    cursor: pointer;

    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;  
}
