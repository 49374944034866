.sepambutton {
    position: absolute;
    border-radius: 50%;
    background: rgb(44, 54, 14);
    color: rgba(183, 162, 162, 0);
    opacity: 0;

    cursor: pointer;
    
    -webkit-user-select: none;
    user-select: none;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.5);

}

/**
    при наведении курсора мыши делаем кнопку немного прозрачной
*/
.sepambutton:hover {
    opacity: 0.5;
}



// @media (max-width: 582px){
    .menu {
        /* position: relative; */
        left: 79px;
        top: 214px;
        width: 30px;
        height: 30px;
        // z-index: 1000;
    }
    .Reset {
        /* position: relative; */
        left: 385px;
        top: 210px;
        width: 37px;
        height: 37px;
        // z-index: 1000;
    }
    .right {
        /* position: relative; */
        left: 251px;
        top: 287px;
        width: 52px;
        height: 25px;
        // z-index: 1000;
    }
    .left {
        /* position: relative; */
        left: 147px;
        top: 287px;
        width: 52px;
        height: 25px;
        // z-index: 1000;
    }
    .plus {
        left: 211px;
        top: 249px;
        width: 30px;
        height: 30px;
        
        // z-index: -1;
    }
    .minus {
        // z-index: -1;
        
        left: 211px;
        top: 317px;
        width: 30px;
        height: 30px;
    }
    .Enter {
        // z-index: -1;
        left: 295px;
        top: 336px;
        width: 37px;
        height: 37px;
    }
    .esc {
        // z-index: -1;
        left: 132px;
        top: 337px;
        width: 30px;
        height: 30px;
    }

// @media (min-width: 583px){
//     .menu {
//         /* position: relative; */
//         left: 149px;
//         top: 258px;
//         width: 30px;
//         height: 30px;
//     }
//     .reset {
//         /* position: relative; */
//         left: 459px;
//         top: 258px;
//         width: 30px;
//         height: 30px;
//     }
// }