.injector {
    margin: 10px 30px 10px 30px;
    &__value {
        width: 100px;
        text-align: center;
        margin: 0px 10px 0px 20px;
    }
    &__controls, &__settings {
        width: 220px;
    }
    &__form {
        display: flex;
        align-items: center;        
        gap: 50px;
        opacity: 100%
    }
    &__button-on, &__button-off {
        margin: 10px;
    }


}