.device_wrapper {
    position: relative; 
    display: block;
    margin-left: 50px;
    margin-right: 50px;
}
.device {
    z-index: 500;
    width: 550px;
}
