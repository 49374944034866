@font-face {
    font-family: "LCD14Italic";
    src: url("./../../assets/fonts/LCD14Italic.otf") format("opentype");
}
    
.display{
    font-family: LCD14Italic;
}

.string1 {
    font-size: 20px;
    color: black;

    font-size: 28px;

    position: absolute;
    top: 100px;
    left: 117px;
    width: 400px;
    text-align: inherit;
}

.string2 {

    font-size: 17px;

    position: absolute;
    top: 140px;
    left: 117px;
    width: 400px;
    text-align: inherit;
    color: black;
}
.scrollstring {
    display: block;
    width: 200px;
    background: gray;
    overflow: hidden;
    padding: 5px;
  }
  
 .scrollstring>span {
    display: inline-block;
    white-space: nowrap;
    animation: runline 20s linear infinite 1s both;
  }
  
  .scrollstring:hover>span {
    animation-play-state: paused;
  } 
  
  @keyframes scrollstring {
    to {transform: translateX(-100%);}
  } 

.menu_pointer{
    position: absolute;
    left: 102px;
    font-size: 15px;
    height: 90px;
    width: 10px;
}
.metering{
    top: 95px;
}
.protection{
    top: 125px;
}
.settings{
    top: 155px;
}