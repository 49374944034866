.led {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: orange;
    opacity: 100%;  
    left: 399px;
    top: 94px;
    width: 10px;
    height: 10px;  
    z-index: 0;
}

.led::before {
    content: "";
    position: absolute;
    background: yellow;
    left: 2px;
    top: 2px;
    width: 2px;
    height: 2px;
    z-index: 1;
    opacity: 50%;
}